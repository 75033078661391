import React, { useState, useEffect } from 'react'
import Mega from '../components/mega';
import data from '../data/data.json';
import '../styles/index.css'
// import { useQueryParam, NumberParam } from "use-query-params";
// import { useQueryParam, StringParam, NumberParam } from "use-query-params";
// import withLocation from "../components/withLocation"
import { CookiesProvider, useCookies } from 'react-cookie';

const COOK = `ubs_mega`

const MegaPage5 = () => {

  const mega = 4;
  const [cookies, setCookie] = useCookies([COOK]);
  const [firststart, setFirststart] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    let nma = (cookies[COOK]) ? cookies[COOK] : [false,false,false,false,false,false,false];
    if(!nma[mega]){
      nma[mega] = true;
      setCookie(COOK, nma, { path: '/' });
    }
    setFirststart(false);
  }, []);

  return (
  <CookiesProvider>
    <div className={`ubs`}>
      <Mega 
      	data={data}
        firststart={ firststart }
      	megaid={ mega }
        cookies={ (firststart) ? [false,false,false,false,false,false,false] : cookies[COOK] }
      />
    </div>
  </CookiesProvider>
)}

// export default withLocation(MegaPage5)
export default MegaPage5